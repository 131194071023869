@use "@general/styles/components/_variables" as var;
@use "@general/styles/bootstrap/boostrap_configuration" as bst;
.who-trust-us{
    text-align: center;
    .ofc{
        img{
            mix-blend-mode: multiply;
            width: 250px;
        }
    }
    .planete-chocolat{
        img{
            width: 100px;
        }
    }
    .osr{
        img{
            width: 100px;
        }
    }
    img{
        width: 150px;
        height: auto;
        max-width: 100%;
    }
    .client{
        height: 150px;  
        display: flex;
        align-items: center;   
        justify-content: center; 
        background-color: bst.$gray-100;
    }
    .swiper-pagination-bullet{
        background-color: bst.$dark;
        &.swiper-pagination-bullet-active{
            background-color: bst.$gray-600;
        }
    }
}